body .btn,
body .btn:hover,
body .btn:focus,
body .btn:active {
  @apply text-white px-8 py-2 border-0 rounded-none h-auto outline-none normal-case;
  min-height: initial;
}

body .btn:focus-visible {
  @apply text-white px-8 py-2 border-0 rounded-none h-auto outline-none normal-case;
  min-height: initial;
}

body .btn.btn-small {
  @apply px-2;
}

.btn.btn-primary {
  @apply bg-gradient-to-r from-gradient2_from to-gradient2_to;
}

.btn.btn-secondary,
.btn.btn-secondary:hover,
.btn.btn-secondary:active,
.btn.btn-secondary:focus {
  @apply bg-white text-gray-dark border-white;
}

.btn.btn-secondary:focus-visible {
  @apply bg-white text-gray-dark border-white;
}

body .btn.btn-gray {
  @apply bg-white-primary hover:bg-white-primary text-gray-dark hover:opacity-100 font-normal;
}

.btn:hover,
.btn.btn-primary:hover {
  @apply opacity-80;
}

body .input,
body .checkbox,
body .select {
  @apply rounded-none bg-white;
}

body .input:focus,
body .select:focus,
body .checkbox:focus {
  border-color: hsl(var(--bc)/var(--tw-border-opacity));

  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-width: 0;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

body .select {
  @apply py-0 font-medium;
}

body .label {
  @apply px-0 pb-1;
}

body .label-text {
  @apply text-gray-dark font-semibold text-xs;
}

.suggestions {
  @apply absolute bg-white border border-white-dark mt-16 max-h-52 overflow-auto pl-0;

  list-style: none;
  width: calc(300px + 1rem);
  z-index: 1;
}

.suggestions li {
  @apply p-2;
}

.suggestions li:hover {
  @apply text-primary-darker cursor-pointer font-bold;
}

.suggestions li:not(:last-of-type) {
  @apply border-b border-white-dark;
}
