/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body .divider:after,
body .divider:before {
  @apply bg-white-dark h-[1px];
}

.tab {
  @apply mt-1;
}

body .tab-active.tab-lifted {
  @apply bg-white border-white-dark;
}

body .tab-lifted {
  @apply border-b-white-dark;
}

body .space-y-3 .no-space-y:not([hidden]) {
  @apply my-0;
}
