html,
html body {
  @apply text-base font-normal text-gray-dark;
}

:focus-visible {
  outline: none;
}

body h1 {
  @apply text-2xl md:text-xl font-bold text-gray-darker;
}

body h2 {
  @apply text-3xl md:text-2xl font-bold text-gray-darker;
}

body h3 {
  @apply text-3xl font-normal text-gray-darker;
}

body h4 {
  @apply text-4xl font-normal text-gray-darker;
}

body h5 {
  @apply text-sm md:text-5xl font-bold text-gray-darker;
}

body h6 {
  @apply text-6xl font-normal text-gray-darker;
}

.body1 {
  @apply text-base font-normal;
}

.body2 {
  @apply text-sm font-normal;
}

.subtitle1 {
  @apply text-sm font-normal;
}

.subtitle2 {
  @apply text-xs font-normal;
}

.caption {
  @apply text-xs font-normal;
}

body .btn {
  @apply text-sm font-semibold;
}

a {
  @apply font-semibold outline-none;
}
